import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const RowerowyPage = () => (
  <Layout>
    <SEO title="Rodzinny Weekend w Gdańsku" />

    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/rowerowy-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>GDAŃSK POSIADA OKOŁO 185 KM TRAS ROWEROWYCH</h3>
          <h1>Rowerowy Weekend w Gdańsku!</h1>
          <p>
            Gdańsk jest jednym z najbardziej rozwiniętych miast w Polsce pod
            względem infrastruktury rowerowej. Zapraszamy więc do zwiedzania
            Gdańska na rowerze. Zobaczycie historyczną część miasta i
            odwiedzicie miejsca, do których nie można wjechać samochodem.
            Zwiedzanie Głównego i Starego Miasta częściowo odbędzie się pieszo.
            Oprócz zwiedzania chcielibyśmy pokazać Wam piękno przyrody naszego
            regionu. Dlatego drugiego dnia pobytu z chęcią pokażemy Wam
            dzielnicę miasta, która otoczona jest lasami i znajduje się na
            terenie Trójmiejskiego Parku Krajobrazowego. To propozycja dla
            wszystkich, którzy lubią sport i zdrowy tryb życia wraz z
            odkrywaniem nowych miejsc do odpoczynku.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>
                  Wycieczka rowerowa z przewodnikiem po Głównym i Starym Mieście
                </li>
                <li> Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>
                  Przejazd rowerem z centrum miasta do dzielnicy Brzeźno i
                  dalsza przejażdżka ścieżką rowerową wzdłuż Zatoki Gdańskiej
                  (Brzeźno, Jelitkowo, Sopot)
                </li>
                <li>
                  Obiad w restauracji z lokalną kuchnią z widokiem na morze
                </li>
                <li>
                  Przejazd rowerem wśród terenów zielonych w dzielnicy Dolina
                  Radości w Oliwie
                </li>
                <li> Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Rejs „pirackim” statkiem na Westerplatte, na pokładzie jest
                  bar oraz możliwość koncertu na żywo muzyki szantowej
                </li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default RowerowyPage
